<template>
  <div class="headerdetai">
    <div class="header_left">
      <div class="backdiv oneitem_center" @click="backclick" v-show="backshow"><i class="cubeic-back"></i></div>
      <h1 class="headertitle">{{headertitle}}</h1>
    </div>

    <div class="header_right" v-show="userIcon">
      <cube-button icon="cubeic-person" :light="true" @click="goToUser"></cube-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'v-header',
  props: {
      headertitle: String,
      backshow: Boolean,
      userIcon: {
          default: false,
          type: Boolean
      }
  },
  data () {
      return {
          logourl: '../../assets/logo_h.png'
      }
  },
  methods: {
      backclick () {
        if (window.history.length <= 2) {
          this.$router.push({ path: '/' })
          return false
        } else {
          this.$router.go(-1)
        }
      },
      goToUser () {
          this.$router.push({ name: 'user' })
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.headerdetai
  display flex
  height 50px
  background white
  border-bottom 1px solid #e3e3e7
  .header_left
    flex 1
    height 100%
    display: flex;
    align-items: center;
    margin-left 10px
    .backdiv
      height 100%
      width 40px
      i
        font-size: 20px;
        color #ee4d2d
    .headertitle
      color #ee4d2d
      font-weight 600
  .header_right
    flex 1
    height 100%
    button
      height 100%
      width 60px
      float right
      i
        font-size: 26px !important
.cube-btn
  i
    font-size: 26px !important
</style>
